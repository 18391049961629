import React, {Component} from 'react';
import FavoriteRecipeButton from './FavoriteRecipeButton';
import {LazyLoadComponent} from 'react-lazy-load-image-component';
import HtmlParser from 'react-html-parser';
import {Link} from 'gatsby';
import * as routes from '../../constants/routes';

export default class RecipeBookTile extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      favorite: {},
      lockStatus: '',
      imgSmall: false
    };
  }
  handleClickLockedTile(e) {
    this.setState({lockStatus: 'clicked'})
  }
  handleMouseOnLockedTile(e) {
    this.setState({lockStatus: 'hovering'})
  }
  handleMouseOffLockedTile(e) {
    this.setState({lockStatus: ''})
  }
  render() {
    let verified = this.props.user.verified === true;
    let premium = this.props.premium === true;
    let memberOnly = this.props.memberOnly === true;
    let {thumbUrl, uid, user} = this.props;
    let unlocked = (verified === true && premium === true) || (verified === true && premium === false) || (verified === false && premium === false) || (uid.length === 0 && memberOnly);
    return (
      <React.Fragment>
        {thumbUrl && <div className={"text-center lg:w-3/12 md:w-4/12 sm:w-6/12 xs:w-6/12 px-4"}>
          <div className="mealTileWrapper mb-4">
            {unlocked
              ? <Link to={this.props.link}>
                  <LazyLoadComponent threshold={400}>
                    <div className="bg-gray-200">
                      <img
                        alt={this.props.title}
                        effect="blur"
                        src={this.props.gravUrl + this.props.thumbUrl}
                        width="600px"
                        height="600px"
                        className="block"/>
                    </div>
                  </LazyLoadComponent>
                </Link>
              : <div>
                <button
                  className="lockedRecipeTile"
                  data-status={this.state.lockStatus}
                  onClick={this
                  .handleClickLockedTile
                  .bind(this)}
                  onMouseEnter={this
                  .handleMouseOnLockedTile
                  .bind(this)}
                  onMouseLeave={this
                  .handleMouseOffLockedTile
                  .bind(this)}>
                  <div className="displayLockWrapper">
                    <div className="displayLock">
                      <div className="displayLockContent">
                        <span className="flaticon-lock-1">
                          <small>
                            <p>This recipe is locked<br/>Verify your account to unlock</p>
                          </small>
                          <Link to={routes.FREETRIAL} className="block">
                            <button
                              className="bg-white text-xs py-2 px-4 inline-block rounded-lg shadow-lg text-secondary">
                              Verify Account
                            </button>
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                  <LazyLoadComponent threshold={400}>
                    <div className="bg-gray-200">
                      <img
                        alt={this.props.title}
                        src={this.props.gravUrl + this.props.thumbUrl}
                        width="600px"
                        height="600px"
                        className="block"/>
                    </div>
                  </LazyLoadComponent>
                </button>
              </div>
            }
            <h4 className="recipeBookTitle font-medium text-sm">{HtmlParser(this.props.title)}</h4>
            {user && unlocked && 
              <FavoriteRecipeButton route={this.props.link} title={this.props.title}/>
            }
          </div>
        </div>
      }
      </React.Fragment>
    );
  }
}