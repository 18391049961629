import React from 'react';
import RecipeBook from './RecipeBook';
import Helmet from 'react-helmet';
import { connect } from "react-redux";
import { storeUser, storeUserId, storeRecipes, addNotification, setStarAdmin } from "../../redux/actions/";
let siteURL = process.env.REACT_APP_APP_URL;
let gravURL = process.env.REACT_APP_GRAV_URL;
const mapStateToProps = (state, props) => {
    return {user: state.user, uid: state.uid, recipes: state.recipes, pageContext: props.pageContext};
};
function mapDispatchToProps(dispatch) {
	return {
        storeUser: user => dispatch(storeUser(user)),
        storeRecipes: recipes => dispatch(storeRecipes(recipes)),
        storeUserId: id => dispatch(storeUserId(id)),
        addNotification: notification => dispatch(addNotification(notification)),
        setStarAdmin: admin => dispatch(setStarAdmin(admin))
	};
}
export class RecipesPage extends React.Component {	
	componentDidUpdate(prevProps) {
		if(this.props.user !== prevProps.user){
			this.checkFavorites();
		}
	}
	componentDidMount() {
		this.checkFavorites();
	}
	checkFavorites(){
		this.props.storeRecipes(this.props.pageContext.recipes);
		
	}
	render() {
		let {title,keyWords,url,facebookTitle,facebookDesc,shareImg,googleDesc} = this.props.pageContext.head;
		const {location} = this.props;
		return (
			<main>
				<Helmet>
					<title>Recipes | New Image Keto | Alpha Lipid™ SDII Programme</title>
					{location&&<meta property="og:url" content={location.href} />}	
					<meta property="og:image" content={gravURL + shareImg} />
					{googleDesc&&<meta name="description" content={googleDesc}/>}
					{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta property="og:url" content={url?siteURL + url:siteURL} />
					<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
					<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
					<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
					<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
					<meta property="og:image:url" content={gravURL + shareImg}/>
					<meta name="twitter:image" content={gravURL + shareImg}/>
					<meta name="twitter:card" content={gravURL + shareImg}/>
				</Helmet>
				<RecipeBook 
					user={this.props.user} 
					uid={this.props.uid} 
					frontmatter={this.props.pageContext.frontmatter}
					pages={this.props.pageContext}
				/>
			</main>
		)
	}
}
const Recipes = connect(mapStateToProps, mapDispatchToProps)(RecipesPage);

export default Recipes;