import React, { Component } from 'react';
import RecipeBookTile from './RecipeBookTile';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import PageHeader from './../PageComponents/PageHeader';
import '../../Content/css/recipesPage.css';
import { connect } from "react-redux";
let gravURL = process.env.REACT_APP_GRAV_URL;
const mapStateToProps = (state, props) => {
	return { 
        user: state.user, 
        uid: state.uid, 
        recipes: state.recipes, 
        frontmatter: props.frontmatter
    };
  };
const RecipesConnected = ({ user, uid, recipes, frontmatter }) => (
    <RecipeBook user={user} 
        uid={uid} 
        recipes={recipes} 
        frontmatter={frontmatter} 
    />
);  
class RecipeBook extends Component {
	constructor(props) {
	  super(props);
	  this._isMounted = false;
	  this.state = {
	  	gravURL: gravURL + '/uploads/images/recipes/',
	  	uid: this.props.uid,
	  	user: {},
	  	favoriteRecipes: {},
	  	filteredRecipes: [],
	  	searchField: '',
	  	wrapperStyle:{},
	  	vegeterianFilter: false,
	  	breakfastFilter: false,
	  	dinnerFilter: false,
		snackFilter: false,
	  	vegeterianRecipes: [],
	  };
	  this.filterItems = this.filterItems.bind(this)
	}
	componentDidMount(){
        
		this._isMounted = true;
		let windowHeight = window.innerHeight;
		let style = {minHeight: windowHeight}
		this.setState({wrapperStyle: style})
	}
	
	filterItems(items){
		let filteredItems = this.props.recipes.pages;
		filteredItems = filteredItems.filter((item) =>{
			let recipes = item.searchString
			return recipes.indexOf(items.toLowerCase()) !== -1;	
		})
		
		if(this.state.vegeterianFilter === true){
			filteredItems = filteredItems.filter((item) =>{
				const recipes = item.searchString
				return recipes.indexOf('vegeterian') !== -1;	
			})
		}
		if(this.state.breakfastFilter === true){
			filteredItems = filteredItems.filter((item) =>{
				const recipes = item.searchString
				return recipes.indexOf('breakfast') !== -1;	
			})
		}
		if(this.state.dinnerFilter === true){
			filteredItems = filteredItems.filter((item) =>{
				const recipes = item.searchString
				return recipes.indexOf('dinner') !== -1;	
			})
		}
		if(this.state.snackFilter === true){
			filteredItems = filteredItems.filter((item) =>{
				const recipes = item.searchString
				return recipes.indexOf('snack') !== -1;	
			})
		}
		this.setState({
			filteredRecipes: filteredItems
		})
	}
	handleVegeterianFilter(){
		this.setState({filterTouched: true})
		if(this.state.vegeterianFilter === false){
			this.setState({vegeterianFilter: true})
		}
		else {
			this.setState({vegeterianFilter: false})
		}
		setTimeout(()=>{this.filterItems(this.state.searchField)}, 100);
	}
	handleDinnerFilter(){
		this.setState({filterTouched: true})
			
		if(this.state.dinnerFilter === false){
			this.setState({dinnerFilter: true, breakfastFilter: false, snackFilter: false})
		}
		else {	
			this.setState({dinnerFilter: false, breakfastFilter: false, snackFilter: false})
		}
		setTimeout(()=>{this.filterItems(this.state.searchField)}, 10);
		
	}
	handleBreakfastFilter(){
		this.setState({filterTouched: true})
		if(this.state.breakfastFilter === false){
			this.setState({breakfastFilter: true, dinnerFilter: false, snackFilter: false})
		}
		else {
			this.setState({breakfastFilter: false, dinnerFilter: false, snackFilter: false})
		}
		setTimeout(()=>{
			this.filterItems(this.state.searchField)
		}, 10);
	}
	handleSnackFilter(){
		this.setState({filterTouched: true})
			
		if(this.state.snackFilter === false){
			this.setState({breakfastFilter: false, dinnerFilter: false, snackFilter: true})
		}
		else {
			this.setState({breakfastFilter: false, dinnerFilter: false, snackFilter: false})
		}
		setTimeout(()=>{
			this.filterItems(this.state.searchField)
		}, 10);
	}
	
	handleSearchFilter(e){
		this.setState({searchField: e.target.value})
		this.filterItems(e.target.value);
	}
	handleCancelSearch(){
		this.setState({searchField: '',})
		this.filterItems('');
	}
	componentWillUnmount(){
		this._isMounted = false;
	}
	render() {
		let filterStarted = this.state.searchField.length > 0 || this.state.filterTouched === true;
		let dataFound = this.state.filteredRecipes.length > 0;
		let recipes = this.props.recipes.pages.map((item, key) =>
			<LazyLoadComponent key={key}>
				<RecipeBookTile 
					gravUrl={gravURL}
					title={item.title}
					thumbUrl={item.thumbnail}
					index={key}
					premium={item.premium}
					memberOnly={item.member}
					uid={this.props.uid}
					link={item.url}
					user={this.props.user}
					vegeterian={item.vegeterian}
				/>
			</LazyLoadComponent>	

			);
			let filteredRecipes = this.state.filteredRecipes.map((item, key) =>
				<LazyLoadComponent key={key}>
					<RecipeBookTile
						gravUrl={gravURL}
						premium={item.premium}
						memberOnly={item.member}
						title={item.title}
						link={item.url}
						thumbUrl={item.thumbnail}
						index={key}
						uid={this.props.uid}
						user={this.props.user}
					/>
				</LazyLoadComponent>
			);
			return (
				<>
				
				<div className="recipeBook" style={this.state.wrapper}>
					<section>
						<PageHeader 
							mainHeading={this.props.frontmatter.pageMainHeading}
							subHeading={this.props.frontmatter.pageSubHeading}
						/>
							<div className="text-center mt-4">
								<label htmlFor="recipe-search">Search Recipes</label>
							</div>
							<div className="searchFilter">
								<span className="flaticon-search"></span>
								<input 
									type="text" 
									onChange={this.handleSearchFilter.bind(this)} 
									value={this.state.searchField}
									className="searchField"
									placeholder="Search recipes"
									id="recipe-search"
									name="recipe-search"
								/>
								
								{filterStarted&&
									<button className="flaticon-close" onClick={this.handleCancelSearch.bind(this)}>
										<span className="hidden">cancel</span>
									</button>
								}
							</div>
							<div>
								{filterStarted?
									<div>
										{dataFound?
											null:
											<div className="searchFilterNoResults py-10">
												<h3>No Recipes found.  Try again.<br/> 
												You can search by ingredient too</h3>
												
											</div>
										}
									</div>
								:null
								}
							</div>
						
					</section>
					<div className="text-center recipeFilterWrapper mb-20">
						<div className="recipeFilter">
							<div>
								<button 
									className="tickBox" 
									onClick={this.handleVegeterianFilter.bind(this)} 
									data-ticked={this.state.vegeterianFilter}
								>
									<span className="hidden">Vegetarian</span>
								</button>
								Vegetarian
							</div>
							<div>
								<button 
									className="tickBox" 
									onClick={this.handleBreakfastFilter.bind(this)} 
									data-ticked={this.state.breakfastFilter}
								>
									<span className="hidden">Breakfast</span>
								</button>
								Breakfast
							</div>
							<div>
								<button 
									className="tickBox" 
									onClick={this.handleDinnerFilter.bind(this)} 
									data-ticked={this.state.dinnerFilter}
								>
									<span className="hidden">Lunch & Dinner</span>
								</button>
								Lunch & Dinner
							</div>
							<div>
								<button 
									className="tickBox" 
									onClick={this.handleSnackFilter.bind(this)} 
									data-ticked={this.state.snackFilter}
								>
									<span className="hidden">snacks</span>
								</button>
								Snacks
							</div>
						</div>
					</div>
					{filterStarted?
						<div>
							{dataFound?	
								<div className="pageGrid" style={this.state.wrapperStyle}>
									<div className="flex flex-wrap">
										{filteredRecipes}
									</div>
								</div>
							:
								null
							}
						</div>
						:
						<div className="pageGrid" style={this.state.wrapperStyle}>
							<div className="flex flex-wrap">
								{recipes}
							</div>
						</div>
					}
				</div>
				
				</>
			);
		}

	
}

const Recipes = connect(mapStateToProps)(RecipesConnected);

export default Recipes;